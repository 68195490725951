<template>
    <div>
        <div class="section" v-for="subCategory in subCategories" v-bind:key="subCategory.id">
            <span @click="handleCategoryClick(subCategory)">
                <div class="card">
                <div v-if="subCategory.premium_content" class="card__premium"><img src="@/assets/ic_20_premium.svg" alt=""></div>
                <div v-if="subCategory.parent" class="card__title">{{ subCategory.name }}</div>
                <div v-else class="card__title">{{ subCategory.name }} Mix</div>
                <div class="card__subtitle">{{ subCategory.subtitle }}</div>
                <img class="card__image" :src="'https://app.sportlerplus.com/scaled/800/' + subCategory.image_url" alt="" />
                </div>
            </span>
        </div>

        <PremiumHintModal></PremiumHintModal>

    </div>
</template>

<script>
import PremiumHintModal from '@/components/PremiumHintModal.vue';

export default {
    name: 'PlaylistsByCategory',
    data() {
        return {
            categories: [],
            subCategories: [],
            category: "",
        }
    },
    components: {
        PremiumHintModal,
    },
    methods: {
        init(categoryId) {
            this.$store.dispatch('fetchCategories').then( () => {
                this.categories = this.$store.getters.getCategories;
                this.category = this.categories.find(i => i.id == categoryId);
                this.subCategories = this.findSubCategories(categoryId);
            });
        },
        findSubCategories(parent_id) {
            let sub_categories = this.categories.filter(i => i.parent != null && i.parent.id == parent_id);
            //if (sub_categories.length == 0) {
            // always append own parent category?           
            sub_categories.push(this.categories.find(i => i.id == parent_id));
            //}
            return sub_categories;
        },
        handleCategoryClick(category) {
            if (category.premium_content && Object.keys(this.premiumStatus).length === 0) {
                this.$modal.show('premiumHint');
            }
            else {
                this.$router.push("/playlist/start?category_id=" + category.id);
            }
		},
    },
    mounted() {
        this.init(this.$route.params.categoryId);
    },
    computed: {
        premiumStatus() {
            return this.$store.getters.getPremiumStatus;
        },
    },
}
</script>

<style scoped>
a {
    text-decoration: none;
}
</style>